<template>
  <div>
    合作专家管理
  </div>
</template>

<script>
export default {
  name: 'collaborativeExpert'
}
</script>

<style scoped lang="less">

</style>
